<template>
  <div id="editCouponDialog">
    <el-dialog
      :title="couponId ? '编辑优惠券' : '新增优惠券'"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="140px"
      >
        <el-form-item label="优惠券名称" prop="couponName">
          <el-input
            v-model="addForm.couponName"
            placeholder="请输入优惠券名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="优惠券类型" prop="couponType">
          <el-select
            v-model="addForm.couponType"
            placeholder="请选择优惠券类型"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item of select_couponType"
              :key="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最高抵用金额" prop="couponValue">
          <el-input
            v-model.number="addForm.couponValue"
            placeholder="请输入最高抵用金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="过期天数" prop="expireDays">
          <el-input
            v-model.number="addForm.expireDays"
            placeholder="请输入过期天数"
          ></el-input>
        </el-form-item>
        <el-form-item label="可用数量" prop="availNum">
          <el-input
            v-model.number="addForm.availNum"
            placeholder="请输入可用数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="最多可领" prop="maxIssue">
          <el-input
            v-model.number="addForm.maxIssue"
            placeholder="请输入最多可领"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="addForm.couponType === '按时间'"
          label="前多少小时可免"
          prop="couponHours"
        >
          <el-input
            v-model.number="addForm.couponHours"
            placeholder="请输入前多少小时可免"
          ></el-input>
        </el-form-item>
        <el-form-item v-else label="前多少小时可免" prop="number">
          <el-input
            v-model.number="addForm.couponHours"
            placeholder="请输入前多少小时可免"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否为默认优惠券" prop="isDefault">
          <el-radio-group v-model="addForm.isDefault">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      couponId: "",
      addForm: {
        couponName: "",
        couponType: "",
        couponValue: "",
        expireDays: "",
        availNum: "",
        maxIssue: "",
        isDefault: "",
        couponHours: "",
      },
      addFormRules: {
        couponName: [
          { required: true, message: "请输入优惠券名称", trigger: "blur" },
        ],
        couponType: [
          { required: true, message: "请选择优惠券类型", trigger: "change" },
        ],
        couponValue: [
          { required: true, message: "请输入最高抵用金额", trigger: "blur" },
          { type: "number", message: "最高抵用金额必须为数字值" },
        ],
        expireDays: [
          { required: true, message: "请输入过期天数", trigger: "blur" },
          { type: "number", message: "过期天数必须为数字值" },
        ],
        availNum: [
          { required: true, message: "请输入可用数量", trigger: "blur" },
          { type: "number", message: "可用数量必须为数字值" },
        ],
        maxIssue: [
          { required: true, message: "请输入最多可领", trigger: "blur" },
          { type: "number", message: "最多可领必须为数字值" },
        ],
        isDefault: [
          {
            required: true,
            message: "请选择是否为默认优惠券",
            trigger: "change",
          },
        ],
        couponHours: [
          { required: true, message: "请输入前多少小时可免", trigger: "blur" },
          { type: "number", message: "小时必须为数字值" },
        ],
        number: [{ type: "number", message: "必须为数字值" }],
      },
    };
  },
  methods: {
    reset() {
      this.couponId = "";
      this.$set(this, "addForm", {
        couponName: "",
        couponType: "",
        couponValue: "",
        expireDays: "",
        availNum: "",
        maxIssue: "",
        isDefault: "",
        couponHours: "",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let url = "/coupon/add";
        let params = {
          ...this.addForm,
          merchantId: this.$route.params.id,
        };
        if (this.couponId) {
          (url = "/coupon/edit"), (params.couponId = this.couponId);
        }
        let res = await this.$http.post(url, params);
        if (res.code === 0) {
          this.getList();
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#editCouponDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
